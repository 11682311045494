.tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(1, 15, 4, 0.075); /* Background color adjusted to match main color #010F04 */
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  background: #0CE93C; /* Active tab background color set to main color #0CE93C */
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: #010F04; /* Color adjusted to match a complementary shade */
}

button {
  border: none;
  color: #010F04; /* Adjusted text color to match main color #010F04 */
}

.content-tabs {
  flex-grow: 1;
}

.content {
  background: white;
  /* padding: 20px; */
  width: 100%;
  height: 100%;
  display: none;
}

.content h2 {
  padding: 0px 0 5px 0px;
}

.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}

.content p {
  width: 100%;
  height: 100%;
}

.active-content {
  display: block;
}
