@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SwitzerReg';
  src: url('../public/Font/Switzer-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SwitzerMed';
  src: url('../public/Font/Switzer-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SwitzerLight';
  src: url('../public/Font/Switzer-Light.otf') format('opentype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html{
  scroll-behavior: smooth;
}

.blur1 {
  right: 0;
  top: 0
}

.blur1,.blur2 {
  position: absolute;
  z-index: -1
}

.blur2 {
  left: -420px;
  top: 630px
}

.blur3 {
  top: 2630px
}

.blur3,.blur4 {
  display: none;
  position: absolute;
  right: 0;
  z-index: -1
}

.blur4 {
  top: -650px;
  width: 500px
}

.blurLast {
  bottom: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1
}

.heading-main {
  word-wrap: break-word;
  color: #fff;
  font-family: SwitzerMed;
  font-size: 62px;
  font-weight: 600;
  line-height: 96px;
  text-transform: capitalize
}

.heading-main span:not(:last-child) {
  display: block;
  font-family: SwitzerLight
}

.head-span {
  color: #0ce93c;
  font-size: 14px;
  letter-spacing: 2.8px;
  line-height: 23.8px;
  text-transform: uppercase
}

.head-span,.span-main {
  word-wrap: break-word;
  font-family: SwitzerReg;
  font-weight: 400
}

.span-main {
  color: #fff;
  font-size: 24px;
  line-height: 40.8px;
  text-transform: capitalize
}

.span-main,.span-main-first {
  display: block;
  margin-top: 20px
}

.span-main-first {
  word-wrap: break-word;
  color: hsla(0,0%,100%,.7);
  font-family: SwitzerLight;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: .8px;
  line-height: 32px;
  text-transform: uppercase
}

.paragraph-main {
  word-wrap: break-word;
  color: hsla(0,0%,100%,.5);
  font-family: SwitzerReg;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  margin-bottom: 3rem;
  margin-top: 20px;
  text-transform: capitalize
}

.heading-main span:last-child {
  color: #0ce93c;
  text-transform: uppercase
}

.main-button-green,.main-button-transparent {
  background-color: #0ce93c;
  border: 1px solid #0ce93c;
  font-family: SwitzerReg;
  font-size: 18px
}

.main-button-transparent {
  background: transparent;
  border-color: rgba(12,233,60,.12);
  color: #0ce93c
}

.BinanceRow {
  border-bottom: .5px solid rgba(12,233,60,.1);
  border-top: .5px solid rgba(12,233,60,.1);
  height: 100%;
  width: 100%
}

.MainCard {
  -webkit-backdrop-filter: blur(44px);
  backdrop-filter: blur(44px);
  background: rgba(218,255,228,.02);
  border: .5px solid rgba(12,233,60,.12);
  border-radius: 30px;
  transition: all .3s ease
}

.MainCard:hover {
  background-color: #0ce93c
}

.MainCard .round {
  align-items: center;
  -webkit-backdrop-filter: blur(44px);
  backdrop-filter: blur(44px);
  background: rgba(218,255,228,.02);
  border: .5px solid rgba(12,233,60,.12);
  border-radius: 50%;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100px
}

.MainCard:hover .round {
  background-color: #030312
}

.MainCard h1 {
  word-wrap: break-word;
  color: #fff;
  font-family: SwitzerMed;
  font-size: 28px;
  font-weight: 600;
  line-height: 39.2px;
  text-transform: capitalize
}

.MainCard:hover h1 {
  color: #030312
}

.MainCard p {
  word-wrap: break-word;
  color: hsla(0,0%,100%,.5);
  font-family: SwitzerReg;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  text-transform: capitalize
}

.MainCard:hover p {
  color: rgba(3,3,18,.6)
}

.popular-heading {
  color: #fff;
  font-family: SwitzerMed;
  font-size: 48px;
  line-height: 57.6px
}

.popular-heading,.popular-para {
  word-wrap: break-word;
  text-transform: capitalize
}

.popular-para {
  color: hsla(0,0%,100%,.5);
  font-family: SwitzerReg;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  width: 600px
}

.popular-para span {
  color: #0ce93c
}

.TableRow {
  -webkit-backdrop-filter: blur(44px);
  backdrop-filter: blur(44px);
  background: linear-gradient(211deg,rgba(31,65,32,.16),rgba(31,65,36,0));
  border: .5px solid rgba(12,233,60,.08);
  border-radius: 16px
}

.TableRow p {
  color: #fafafd;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.54px
}

.TableRow .button button,.TableRow p {
  word-wrap: break-word;
  font-family: SwitzerReg
}

.TableRow .button button {
  background: rgba(218,255,228,.04);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  gap: 10px;
  padding: 16px 27px
}

.TrendingCryptoHeading {
  word-wrap: break-word;
  color: #fff;
  font-family: SwitzerMed;
  font-size: 48px;
  font-weight: 600;
  line-height: 57.6px;
  text-transform: capitalize
}

.TrendingCryptoParagraph {
  word-wrap: break-word;
  color: hsla(0,0%,100%,.5);
  font-family: SwitzerReg;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  text-transform: capitalize;
  width: 502px
}

.TrendingCryptoParagraph span {
  color: #0ce93c
}

.TrendingCard {
  -webkit-backdrop-filter: blur(44px);
  backdrop-filter: blur(44px);
  background-position: 50%;
  background-size: 100%;
  background: linear-gradient(45deg,#1f3825,#1b642b,#1f3825);
  border: .5px solid #0ce93c;
  border-radius: 10px;
  box-shadow: 0 4px 60px rgba(12,233,60,.16)
}

.TrendingCard>h1 {
  font-size: 30px
}

.TrendingCard .col h1,.TrendingCard>h1 {
  word-wrap: break-word;
  color: #fff;
  font-family: SwitzerMed;
  font-weight: 500
}

.TrendingCard .col h1 {
  font-size: 28px;
  line-height: 33.6px;
  margin-bottom: 3px;
  text-align: left
}

.TrendingCard .col h1.green {
  color: #0ce93c
}

.TrendingCard .col p {
  word-wrap: break-word;
  color: hsla(0,0%,100%,.5);
  font-family: SwitzerReg;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px
}

.AppCard {
  position: relative;
  z-index: 3
}

.AppCard:not(:last-child):after {
  background-color: rgba(182,243,165,.1);
  content: "";
  height: .5px;
  left: 100px;
  position: absolute;
  top: 50px;
  width: 90%;
  z-index: 1
}

.AppCard img {
  position: relative;
  z-index: 2
}

.AppCard:before {
  background-color: #010f04;
  border-radius: 50%;
  content: "";
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100px
}

.AppCard h1 {
  color: #fff;
  font-size: 32px;
  line-height: 46.4px
}

.AppCard h1,.AppCard p {
  word-wrap: break-word;
  font-family: SwitzerReg;
  text-transform: capitalize
}

.AppCard p {
  color: hsla(0,0%,100%,.5);
  font-size: 20px;
  line-height: 34px
}

.Final {
  position: relative;
  z-index: 2
}

@media screen and (min-width: 1500px) {
  .heading-main {
      font-size:65px
  }
}

@media screen and (min-width: 1700px) {
  .heading-main {
      font-size:70px
  }
}

@media screen and (max-width: 700px) {
  .heading-main {
      font-size:40px;
      line-height: inherit
  }

  .AppCard h1,.MainCard h1 {
      font-size: 20px;
      line-height: inherit
  }

  .MainCard .round {
      height: 70px;
      width: 70px
  }

  .TrendingCryptoHeading,.heading-main,.popular-heading {
      font-size: 25px;
      line-height: inherit
  }

  .popular-para {
      width: 100%
  }

  .TrendingCard>h1 {
      font-size: 24px
  }

  .AppCard h1 {
      margin-top: 10px
  }

  .AppSteps {
      grid-row-gap: 5rem!important;
      grid-template-columns: 1fr 1fr
  }

  .AppCard p {
      font-size: 15px;
      margin-top: 10px
  }

  .span-main {
      font-size: 18px
  }

  .paragraph-main {
      font-size: 15px
  }

  .Footer .start {
      font-size: 14px!important
  }

  .Footer .end {
      font-size: 12px!important
  }

  .main-button-green,.main-button-transparent {
      font-size: 15px
  }

  .Main {
      margin-top: 2rem!important
  }

  .BinanceRow>div {
      grid-template-columns: 1fr 1fr!important
  }

  .Footer>:nth-child(2)>div {
      flex-direction: column
  }

  .Footer .start {
      margin-left: 0;
      margin-top: 1rem;
      text-align: center
  }

  .Header .header-right-button {
      font-size: 15px!important
  }

  .TrendingCryptoParagraph {
      width: 100%
  }

  .TrendingCryptoHeading {
      line-height: inherit;
      text-align: center
  }

  .MainCard p,.TrendingCryptoParagraph,.popular-para {
      font-size: 14px;
      line-height: inherit
  }

  .Header .header-right-button,.Main button {
      height: 45px;
      width: 150px
  }

  .Header {
      padding-left: .5rem!important;
      padding-right: .5rem!important
  }

  .TrendingCard {
      padding-left: 1rem!important;
      padding-right: 1rem!important
  }

  .AppCard img {
      width: 80px
  }

  .AppCard:before {
      height: 80px;
      width: 80px
  }

  .Footer img {
      width: 100px
  }

  .blur2 {
      left: 0;
      top: 930px
  }

  .blur3 {
      top: 2330px
  }

  .blur4 {
      top: 4030px
  }

  .blurLast {
      width: 750px!important
  }
}


body {
  background-color: #010f04
}

a {
  text-decoration: none
}

nav,ul {
  list-style: none
}

button {
  word-wrap: break-word;
  background-color: #0ce93c;
  font-family: SwitzerReg;
  font-size: 16px;
  font-weight: 500
}

.Header {
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background: rgba(218,255,228,.03);
  border: .5px solid rgba(218,255,228,.05);
  width: 97%
}

.Header,body .w-11\/12 {
  max-width: 1560px
}

.Header ul a {
  color: hsla(0,0%,100%,.7);
  font-weight: 400
}

.Header .header-right-button,.Header ul a {
  word-wrap: break-word;
  font-family: SwitzerReg;
  font-size: 16px
}

.Header .header-right-button {
  background-color: #0ce93c;
  color: #030312;
  font-weight: 500
}

.Footer {
  -webkit-backdrop-filter: blur(44px);
  backdrop-filter: blur(44px);
  background: rgba(218,255,228,.01);
  border-top: .5px solid rgba(12,233,60,.12)
}

.Footer .start {
  font-family: SwitzerLight
}

.Footer .end,.Footer .start {
  word-wrap: break-word;
  color: hsla(0,0%,100%,.5);
  font-size: 16px;
  line-height: 25.6px;
  text-transform: capitalize
}

.Footer .end {
  font-family: SwitzerReg
}

.Footer .end span {
  color: #0ce93c
}

#walletname{
  color: #0ce93c;
}