/* NotFound.css */

.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh; /* Adjust height as needed */
  }
  
  .not-found-header {
    font-size: 3rem; /* Large header font size */
    color: #0CE93C; /* Crypto website color */
  }
  
  .not-found-text {
    font-size: 1.5rem; /* Medium text font size */
    margin-bottom: 20px; /* Add spacing below text */
    color: #777; /* Lighter text color */
  }
  
  .not-found-image {
    width: 300px; /* Adjust image size as needed */
    height: auto; /* Maintain aspect ratio */
  }
  