.TrendingCryptoHeading {
    word-wrap: break-word;
    color: #fff !important;
    font-family: SwitzerMed;
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
    text-transform: capitalize;
}

.TrendingCryptoParagraph {
    word-wrap: break-word;
    color: hsla(0, 0%, 100%, .5);
    font-family: SwitzerReg;
    font-size: 18px;
    font-weight: 400;
    line-height: 30.6px;
    text-transform: capitalize;
    width: 502px;
}

.TrendingCryptoParagraph span {
    color: #0ce93c;
}

.TrendingCard {
    -webkit-backdrop-filter: blur(44px);
    backdrop-filter: blur(44px);
    background-position: 50%;
    background-size: 100%;
    background: linear-gradient(45deg, #1f3825, #1b642b, #1f3825);
    border: .5px solid #0ce93c;
    border-radius: 10px;
    box-shadow: 0 4px 60px rgba(12,233,60,.16);
}

.TrendingCard .col h3 {
    font-size: 28px;
    line-height: 33.6px;
    margin-bottom: 3px;
    text-align: left;
}

.TrendingCard .col h3, .TrendingCard>h3 {
    word-wrap: break-word;
    color: #fff;
    font-family: SwitzerMed;
    font-weight: 500;
}

.TrendingCard .col p, .pIcon {
    word-wrap: break-word;
    color: hsla(0, 0%, 100%, .5);
    font-family: SwitzerReg;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
}

button {
    word-wrap: break-word;
    background-color: #0ce93c;
    font-family: SwitzerReg;
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.project-image{
    width: 56px;
    height: 56px;
    border-radius: 8px;
}

.project-icon{
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.fYVeCM {
    background: rgb(222, 251, 240);
    width: fit-content;
    color: rgb(22, 199, 132);
    border-radius: 40px;
    font-size: 11px;
    padding: 2px 12px;
    font-weight: 600;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.fYVeCN {
    background: rgb(222, 251, 240);
    width: fit-content;
    color: rgb(199, 22, 60);
    border-radius: 40px;
    font-size: 11px;
    padding: 2px 12px;
    font-weight: 600;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.fYVeCU {
    background: rgb(222, 251, 240);
    width: fit-content;
    color: rgb(199, 199, 22);
    border-radius: 40px;
    font-size: 11px;
    padding: 2px 12px;
    font-weight: 600;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.gknig {
    margin-right: 4px;
    background: rgb(22, 199, 132);
    width: 8px;
    height: 8px;
    border-radius: 5px;
}

.rknig {
    margin-right: 4px;
    background: rgb(199, 22, 60);
    width: 8px;
    height: 8px;
    border-radius: 5px;
}

.yknig {
    margin-right: 4px;
    background: rgb(199, 199, 22);
    width: 8px;
    height: 8px;
    border-radius: 5px;
}
